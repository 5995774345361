
					@import './src/sass/variables';
					@import './src/sass/mixins';
				






































@mixin text-block($color) {
	background: rgba($color, 0.05);

	.style-icon {
		color: $color;
	}
}

.instructions {
	@include text-block($color__orange);
}

.help {
	@include text-block($color__green);
}

.warning {
	@include text-block($color__red);
}

.info {
	@include text-block($color__blue);
}

.block-inner::v-deep {
	padding: 0 !important;

	&:not(.regular) {
		font-weight: $font__semibold;
	}

	.format-bar {
		left: $site__padding * 2;
	}

	.ProseMirror {
		min-height: $site__padding * 4;
		padding: $site__padding $site__padding * 2;
		font-size: 1em;
		line-height: $line__height;
		background-color: transparent;
		transition: background-color 0.2s ease-out;
		border-radius: 0;
	}

	.ProseMirror-focused {
		box-shadow: none;
	}

	.editable .ProseMirror-focused {
		background-color: $color__white;
	}
}
